import {
  genericService,
  patch,
  get,
  download,
  search,
  remove as importedRemove,
} from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/v1/permits';

export const updateTab = (tab) => (entity) =>
  patch(`${SERVICE_URI}${BASE_URI}/${entity.id}/${tab}`, entity).then((res) =>
    res.json(),
  );

export const exportCSV = (params) => {
  download(`${SERVICE_URI}${BASE_URI}`, params);
};

export const goBack = (id, tab) =>
  importedRemove(`${SERVICE_URI}${BASE_URI}/${id}/${tab}`);

export const getAuditDetails = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/${id}/audit`);

export const findByIdent = (ident) =>
  get(`${SERVICE_URI}${BASE_URI}/${ident}/preview`);

export const { findAll, findOne, create, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);
export const searchAll = (data) =>
  search(`${SERVICE_URI}${BASE_URI}/search`, data);
